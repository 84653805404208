.partners-block {
    background-color: rgba(4, 21, 31, 0.05);
    padding: 55px 0px;

    h2 {
        text-transform: uppercase;
    }

    .col-12 {
        margin-bottom: 30px;
    }

    .partner-block {
        padding: 30px 40px;
        background-color: #fff;
        border: 1px solid #b8b8b8;
        border-radius: 5px;
        height: 100%;

        img {
            margin-bottom: 30px;
            height: 55px;
            max-width: 100%;
        }
    }
}