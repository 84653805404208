.btn-banner {
    padding: 20px 0px;
    
    a {
        text-transform: uppercase;
        border: 1px solid #fff;
        color: #fff;
        border-radius: 2px;
        padding: 7px 38px;
        font-weight: 800;
        font-size: 34px;
        margin-left: auto;
        text-align: center;
        margin-right: auto;
        display: block;
        max-width: 400px;
        &:hover {
            color: #fff;
            text-decoration: none;
        }
    }
}