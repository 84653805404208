.form-banner {

    form {
        display: flex;
        justify-content: center;


        @media (max-width:640px) {
            display: block;
        }
        input[type='text'] {
            border: 1px solid #bfbfbf;
            border-radius: 4px;
            padding: 10px 14px !important;
            outline: none;
            background-color: #ffffff;
            -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #bfbfbf !important;
                opacity: 1; /* Firefox */
            }
            
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #bfbfbf !important;
            }
            
            &::-ms-input-placeholder { /* Microsoft Edge */
                color: #bfbfbf !important;
            }
        }
        
.ginput_container_email {
    max-width: 100%;
    height: auto;
    width: 360px;
    @media (max-width:640px) {
        width: 100%;
    }
}

    input[type='submit'] {
        background: rgb(185,22,64);
        background:  linear-gradient(90deg, rgba(185,22,64,1) 0%, rgba(233,69,69,1) 100%);
    color: #fff;
    font-weight: 800;
    outline: none;
    border: 2px solid #fff;
    border-radius: 4px;
    padding: 9px 25px;
    text-transform: uppercase;
    }
    }


    .gform_confirmation_message {
        color: #fff;
        font-size: 28px;
        line-height: 36px;
    }
}

textarea, input[type="range"], input, input:matches([type="password"], [type="search"]) {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
}