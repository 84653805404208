.footer-top {
    padding: 55px 0px;
    background-color: #04151f;
    color: #fff;
    border-bottom: 4px solid #fff;
    .footer-logo {
        img {
            height: 27px;
        }
        
        
    }
    
    .contact-link {
        margin-bottom: 3px;
        span {
            color: $red;
            margin-right: 6px;
            font-weight: 700;
        }
        a {
            color: #fff;
            font-weight: 700;
            &:hover {
                color: #fff;
                text-decoration: underline;
                
            }
        }
    }
    
    .social-links {
        
        @media (min-width: 991px) {
            position: absolute;
            bottom: 11px;
        }
        
        a {
            margin-right: 10px;
            img {
                height: 28px;
            }
        }
    }
    
}

.footer-bottom {
    background-color: #04151f;
    padding: 15px 0px; 
    span {
        font-size: 14px;
        @media (max-width:991px) {
            display: block;
            margin-bottom: 15px;
        }
        a {
            color: #fff;
            &:hover {
                color: #fff;
            }
        }
    }
}