.support-table {
    
    h3 {
        text-transform: uppercase;
        font-size: 34px;
        line-height: 38px;
    }
    .large-text {
        font-size: 28px;
        line-height: 38px;
        margin-bottom: 15px;
    }
    .col-12 {
        padding-top: 55px;
        padding-bottom: 55px;
    }
    ul {
        padding-left: 20px;
        
        li {
            font-weight: 700;
            list-style: none;
            &:before {
                content: "\2022";
                color: $red;
                font-size: 18px;
                font-weight: bold;
                display: inline-block; 
                width: 1em;
                margin-left: -1em;
            }
        }
    }
    .bg-grey {
        background-color: #eeeff0;
    }
    .list-padding {
        padding-left: 20px;
    }
    p {
        margin-bottom: 5px;
        font-size: 16px;
    }
}