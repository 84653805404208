.hero-project-services {
    padding: 55px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    p {
        font-size:30px;
        line-height: 40px;
        color: #fff;
    }
    h2 {
        text-transform: uppercase;
        font-size: 50px;
    line-height: 60px;
    }
}