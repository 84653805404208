.text-banner {
    padding: 55px 0px;
    position: relative;
    background-size: cover;
    background-position: center ;
    background-repeat: no-repeat;
    text-align: center;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        display: block;
        background-color: rgba(4, 21, 34, 0.9);
        width: 100%;
        transition: all .2s ease;
    }
    h2 {
        text-transform: uppercase;
    }
    p {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 0px;
    }
}