.nav-container {
    padding: 14px 0px;
    
    .side-menu {
        margin-left: 30px;
        position: absolute;
        right: 0;
        height: 88px;
        display: flex;
        align-items: center;
        .more-dropdown {
            color: black;
            font-size: 20px;
            cursor: pointer;
        }
        .more-dropdown.active {
            color: #fff;
            border-bottom: 2px solid #fff;
        }


        .sub-menu {
            display: none;
            position: absolute;
            width: 164px;
            z-index: 999;
            background-color: #fff;
            top: 75px;
            border: 2px solid #fff;
            right: 0;
            .active {
                a {
                    color: #fff;
                    border-bottom: 2px solid #fff;
                }
            }
            ul {
                padding-left: 0px;
                margin-bottom: 0px;
            }
            .current-menu-item {
                a {
                    color: $red;
                }
            }
            li {
                padding: 5px 12px;
                display: block;
                color: #000;
                font-size: 18px;
                text-align: right;
                a {
                    color: #000;
                    &:hover {
                        text-decoration: none;
                        color: #fff;
                        border-bottom: 2px solid #fff;
                    }
                }
            }
        }
        
        &:hover {
            &>.sub-menu {
                display: block;
            }
            .more-dropdown {
                color: #fff;
                border-bottom: 2px solid #fff;
            }
        }


    }
    #menu-primary-navigation {
        margin-bottom: 0px;
        list-style: none;

        .current-menu-item {
            a {
                color: #fff;
                border-bottom: 2px solid #fff;
            }
        }
        li {
            list-style: none;
            display: inline-block;
            margin-left: 30px;

            &:hover {
                a {
                    color: #fff;
                border-bottom: 2px solid #fff;
                }
            }
            a {
                color: black;
                font-size: 20px;
                &:hover {
                    text-decoration: none;
                }
            }
            
        }
    }
}
.site-logo {
    svg {
        height: 27px;
        width: 350px;
        @media(max-width: 576px) {
            height: 22px;
            width: 280px;
        }
    }
}

.navbar {
    padding: 10px 0px;
}



@keyframes bugfix { from {padding:0;} to {padding:0;}}
@-webkit-keyframes bugfix { from {padding:0;} to {padding:0;}}

#overlay-button {
    position: absolute;
    right: 0em;
    top: 5px;
    padding: 26px 11px;
    z-index: 5;
    cursor: pointer;
    user-select: none;
    span {
        height: 4px;
        width: 35px;
        border-radius: 2px;
        background-color: #fff;
        position: relative;
        display: block;
        transition: all .2s ease-in-out;
        &:before {
            top: -10px;
            visibility: visible;
        }
        &:after {
            top: 10px;
        }
        &:before, &:after {
            height: 4px;
            width: 35px;
            border-radius: 2px;
            background-color: #fff;
            position: absolute;
            content: "";
            transition: all .2s ease-in-out;
        }
    }
    
}



#overlay {
    height: 100vh;
    width: 100vw;
    background: $red;
    z-index: 2;
    transform: translateX(100vw);
    position: fixed;
    transition: all .2s ease-in-out;
    left: 0;
    top: 0;
    &.active {
        transform: none;
    }
    ul.sub-menu {
        display: none;
    }
    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        height: 100vh;
        padding-left: 0;
        list-style-type: none;
        margin-left: 23px;

        .current-menu-item {
            
            a {
                color: #fff;
                border-bottom: 3px solid #fff;
            }
        }
        li {
            padding-bottom: 30px;
            a {
                color: #000;
                text-decoration: none;
                font-size: 32px;
                &:hover {
                    color: white;
                }
            }
        }
    }
}

.mobile-menu {
    display: none;
    @media (max-width: 991px) {
        display: block;
    }

    input[type=checkbox] {
        display: none;
    }
    
    input[type=checkbox]:checked ~ #overlay {
        transform: none;
    }
    
    input[type=checkbox]:checked ~ #overlay-button {
        span {
            background-color: #fff;
            &:before {
                background-color: #fff;
            }
            &:after {
                background-color: #fff;
            }
        }
    }
    
input[type=checkbox]:checked ~ #overlay-button {
    &:hover span, span{
        background: transparent;
    }
    span {
        &:before {
            transform: rotate(45deg) translate(7px, 7px);
            opacity: 1;
        }
        &:after {
            transform: rotate(-45deg) translate(7px, -7px);
        }
    }
}
}

.navbar-nav {
    margin-right: 75px;
}

#menu-mobile-menu {
    margin-left: 0px !important;
}