.text-intro {
    padding: 55px 0px;

    h2 {
        text-transform: uppercase;
    }
}

.text-intro-alt {
    padding: 55px 0px 25px 0px;

    h2 {
        text-transform: uppercase;
    }
    .text-intro-alt-icon {
        height: 220px;
    }
}