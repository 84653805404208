.form-block {
    padding: 55px 0px;
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    @media (max-width:1200px) {
        background-position: center;
    }
    @media (max-width:991px) {
        background-position: left;
    }
    h2 {
        color: #04151f;
        text-transform: uppercase;
    }
    p {
        color: #04151f;
        font-size: 30px;
        line-height: 40px;
    }
    
    
    input[type='text'] {
        border: 1px solid #bfbfbf;
        border-radius: 4px;
        padding: 10px 14px !important;
        outline: none;
        background-color: #ffffff;
        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #bfbfbf !important;
            opacity: 1; /* Firefox */
        }
        
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #bfbfbf !important;
        }
        
        &::-ms-input-placeholder { /* Microsoft Edge */
            color: #bfbfbf !important;
        }
    }
    
    textarea {
        border: 1px solid #bfbfbf;
        border-radius: 4px;
        padding: 10px 14px !important;
        outline: none;
        background-color: #ffffff;
        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #bfbfbf !important;
            opacity: 1; /* Firefox */
        }
        
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #bfbfbf !important;
        }
        
        &::-ms-input-placeholder { /* Microsoft Edge */
            color: #bfbfbf !important;
        }
    }
    
    input[type='submit'] {
        background: rgb(185,22,64);
        background:  linear-gradient(90deg, rgba(185,22,64,1) 0%, rgba(233,69,69,1) 100%);
        color: #fff;
        font-weight: 800;
        outline: none;
        border: none;
        border-radius: 4px;
        padding: 9px 25px;
        text-transform: uppercase;
    }
    
    
}

//overriding gform styles 
body .gform_wrapper ul li.gfield {
    margin-top: 0px !important;
    margin-bottom: 16px !important;
}

.gform_wrapper li.hidden_label input {
    margin-top: 0px !important;
}

.contact-page {
    .form-block {
        h2 {
            font-size: 47px;
            line-height: 57px;
        }
    }
}

.signup-form {
    padding: 55px 0px;
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    @media (max-width:1200px) {
        background-position: center;
    }
    @media (max-width:991px) {
        background-position: left;
    }
    
    .gf_progressbar_wrapper {
        display: none;
    }
    h2 {
        color: #04151f;
        text-transform: uppercase;
        font-size: 47px;
        font-weight: 800 !important;
        line-height: 57px;
    }
    .large-text {
        color: #04151f;
        font-size: 30px;
        line-height: 40px;
    }
    p {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 5px;
    }
    
    input[type='text'] {
        width: 100% !important;
        border: 1px solid #bfbfbf;
        border-radius: 4px;
        padding: 10px 14px !important;
        outline: none;
        background-color: #ffffff;
        -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #bfbfbf !important;
            opacity: 1; /* Firefox */
        }
        
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #bfbfbf !important;
        }
        
        &::-ms-input-placeholder { /* Microsoft Edge */
            color: #bfbfbf !important;
        }
    }
    .ginput_container_checkbox {
        margin-top: 0px !important;
    }
    
    textarea {
        border: 1px solid #bfbfbf;
        border-radius: 4px;
        padding: 10px 14px !important;
        outline: none;
        background-color: #ffffff;
        -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #bfbfbf !important;
            opacity: 1; /* Firefox */
        }
        
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #bfbfbf !important;
        }
        
        &::-ms-input-placeholder { /* Microsoft Edge */
            color: #bfbfbf !important;
        }
    }
    
    .gform_next_button {
        background: rgb(185,22,64);
        background:  linear-gradient(90deg, rgba(185,22,64,1) 0%, rgba(233,69,69,1) 100%);
        color: #fff;
        font-weight: 800;
        outline: none;
        border: none;
        border-radius: 4px;
        padding: 9px 25px;
        text-transform: uppercase;
    }
    .gform_previous_button {
        background-color: #000;
        color: #fff;
        font-weight: 800;
        outline: none;
        border: none;
        border-radius: 4px;
        padding: 9px 25px;
        text-transform: uppercase;
    }
    
    input[type='submit'] {
        background: rgb(185,22,64);
        background:  linear-gradient(90deg, rgba(185,22,64,1) 0%, rgba(233,69,69,1) 100%);
        color: #fff;
        font-weight: 800;
        outline: none;
        border: none;
        border-radius: 4px;
        padding: 9px 25px;
        text-transform: uppercase;
        -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    }
    
    .gfield_checkbox {
        
        input {
            margin-top: 0px !important;
        }
        label {
            font-size: 16px !important;
            padding-left: 6px !important;
            a {
                background: rgb(185,22,64);
                background:  linear-gradient(90deg, rgba(185,22,64,1) 0%, rgba(233,69,69,1) 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                border-bottom: 1px solid $red;
            }
        }
    }

    .gfield_html {
        a {
            background: rgb(185,22,64);
            background:  linear-gradient(90deg, rgba(185,22,64,1) 0%, rgba(233,69,69,1) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .gfield_label {
        font-size: 16px !important;
    }
}


.gform_confirmation_message {
    a {
        background: rgb(185,22,64);
        background:  linear-gradient(90deg, rgba(185,22,64,1) 0%, rgba(233,69,69,1) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        border-bottom: 1px solid $red;
    }
}