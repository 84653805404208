.icon-block {
    padding: 55px 0px;
    color: #fff;
    h2 {
        text-transform: uppercase;
    }
    h3 {
        text-transform: uppercase;
    }

    img {
        height: 140px;
    }
}