.hero-home {
    height: 400px;
    display: flex;
    align-items: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    h1 {
        color: #fff;
        font-weight: 400;

        @media (max-width: 767px) {
            font-size: 54px;
            line-height: 62px;
        }
    }
}