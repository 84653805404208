.rate-block {
    padding: 55px 0px;
    position: relative;
    background-size: cover;
    background-position: center ;
    background-repeat: no-repeat;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        display: block;
        background-color: rgba(4, 21, 34, 0.9);
        width: 100%;
        transition: all .2s ease;
    }

    .large-text {
        color: #fff;
        font-size: 65px;
        line-height: 72px;
    }

    .price {
        text-transform: uppercase;
        font-size: 123px;
            line-height: 110px;
        font-weight: 800;
        @media (max-width: 550px) {
            font-size: 80px; 
        }
    }
    p {
        font-size: 30px;
        line-height: 40px;
    }
}