//Add imports here

@import "_hero-home";
@import "_text-intro";
@import "_icon-block";
@import "_organisations-block";
@import "_form-block";
@import "_hero-about";
@import "_text-banner";
@import "_quote-block";
@import "_image-block";
@import "_partners-block";
@import "_hero-payg";
@import "_split-content";
@import "_rate-block";
@import "_btn-banner";
@import "_hero-project-services";
@import "_form-banner";

