.split-content {
    color: $body;
    h2 {
        text-transform: uppercase;
        
    }
    .split-content-block {
        padding: 50px 0px;
    }

    .text-large {
        font-size: 30px;
        line-height: 40px;
    }

    .bg-grey {
        background-color: #eeeff0;
    }
}