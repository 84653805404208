.hero-payg {
    padding: 55px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    h2 {
        text-transform: uppercase;
        font-size:48px;
        line-height: 58px;
        color: #fff;
    }
}