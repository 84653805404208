.quote-block {
    padding: 55px 0px;
    color: #fff;

    p {
        font-size: 30px;
        line-height: 40px;
        text-align: center;
    }
    span {
        display: block;
        font-size: 22px;
        margin-bottom: 8px;
        text-align: center;
    }

    img {
        height: 65px;
    }
}