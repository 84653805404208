
.service-list {
    padding: 55px 0px;


    .service-block-row:last-child {

        .service-block:after {
            display: none;
        }
        .service-block {
            margin-bottom: 0px;
        }
    }
    .service-block {
        border-radius: 8px;
        padding: 20px 20px 20px 10%;
        margin-bottom: 75px;
        position: relative;

        &:after {
            content: "";
            background-image: url("/wp-content/themes/wilsonetheridge/src/images/dots.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 47px;
            width: 9px;
            position: absolute;
            left: 50%;
            bottom: -61px;
        
        }
        img {
            height: 150px;
            width: 150px;
            margin-left: -140px;
            @media (max-width: 767px) {
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 30px; 
            }
        }
        h2 {
            text-transform: uppercase;
        }
        ul {
            padding-left: 20px;
            li {
                color: #fff;
                font-weight: 700;
                font-size: 18px;
                line-height: 28px;

                ul {
                    li {
                        font-weight: 400 !important;
                    }
                }
            }
        }
    }
}