.image-block {
    padding: 55px 0px;

    h2 {
        text-transform: uppercase;
    }
    p {
        font-size: 30px;
        line-height: 40px;
    }
}