.hero-about {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @media (max-width: 991px) {
        background-position: left;
    }
    padding: 55px 0px;
    h2 {
        text-transform: uppercase;
    }
}