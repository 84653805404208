body {
    font-family: "Bilo";
    color: $body;
}

p {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
}

h1,h2,h3,h4,h5 {
    font-weight: 800;
}

h1 {
    font-size: 64px;
    line-height: 75px;
}

h2 {
    font-size: 36px;
    line-height: 48px;
}

h3 {
    font-size: 32px;
    line-height: 40px;
}

.bg-gradient {
    background: rgb(185,22,64);
    background:  linear-gradient(90deg, rgba(185,22,64,1) 0%, rgba(233,69,69,1) 100%);
}


.of-hidden {
overflow: hidden;
}


@media (min-width: 768px) {
    .container, .container-md, .container-sm {
        max-width: 730px;
    }
}

@media (min-width: 992px) {
    .container, .container-lg, .container-md, .container-sm {
        max-width: 1000px;
    }
}
@media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1170px;
    }
}

.gradient-text {
    background: rgb(185,22,64);
    background:  linear-gradient(90deg, rgba(185,22,64,1) 0%, rgba(233,69,69,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


// gravityforms styling


.gform_wrapper div.validation_error {
    display: none;
}

.validation_message {
    display: none !important;
}

.gfield_error {
    background-color: transparent !important;
    margin-bottom: 6px!important;
    border-top: none !important;
    border-bottom:  none !important;
    padding-bottom:  0px !important;
    padding-top:  0px !important;
    box-sizing: border-box;
    
    .gfield_label {
        margin-top: 0px !important;
    }
}


.gform_body {
    .gfield_error {
        
        .ginput_container{
            position: relative;
            &::after {
                content: "";
                background-image: url('/wp-content/themes/wilsonetheridge/src/images/warning-sign-white.svg');
                position: absolute;
                background-repeat: no-repeat;
                top: 0;
                bottom: 0;
                color: #fff;
                background-size: 20px;
                background-position: center;
                right: 0;
                width: 40px;
                background-color: $red;
                padding: 10px;
                text-align: center;
            }
        }
        
        .ginput_container_consent {
            &::after {
                display: none !important;
            }
        }
        
    }
}

.gform_wrapper li.gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), .gform_wrapper li.gfield_error textarea {
    border: 1px solid $red !important;
}

.gform_wrapper .gfield_error .gfield_label {
    color: $red !important;
}

.gform_wrapper.gf_browser_chrome .gfield_error input[type=email], .gform_wrapper.gf_browser_chrome .gfield_error input[type=number], .gform_wrapper.gf_browser_chrome .gfield_error input[type=password], .gform_wrapper.gf_browser_chrome .gfield_error input[type=tel], .gform_wrapper.gf_browser_chrome .gfield_error input[type=text], .gform_wrapper.gf_browser_chrome .gfield_error input[type=url], .gform_wrapper.gf_browser_chrome .gfield_error select, .gform_wrapper.gf_browser_chrome .gfield_error textarea, .gform_wrapper.gf_browser_chrome li.gfield_error.field_sublabel_above .ginput_complex input[type=text] {
    margin-bottom: 0px !important;
}

#newsletter-signup {
    .gform_body .gfield_error .ginput_container::after {
        content: "";
        background-image: url("/wp-content/themes/dos/src/images/warning-sign-white.svg");
        position: absolute;
        background-repeat: no-repeat;
        top: 12px;
        bottom: 0;
        color: #fff;
        background-size: 20px;
        background-position: center;
        left: 240px;
        width: 40px;
        background-color: $red;
        padding: 10px;
        text-align: center;
    }
}

.gform_confirmation_wrapper {
    margin-top: 20px;
}


body img.gform_ajax_spinner {
    display: none !important;
}