.organisations-block {
    padding: 55px 0px;
    position: relative;
    background-size: cover;
    background-position: center ;
    background-repeat: no-repeat;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        display: block;
        background-color: rgba(4, 21, 34, 0.9);
        width: 100%;
        transition: all .2s ease;
    }

    h2 {
        text-transform: uppercase;
    }

    .organisation {
        img {

            @media (max-width:767px) {
                max-width: 290px;
            }
          
        }
    }
}